/*
 * SDK连接相关
 */

import configs from './config'

let SDK = require('./NIM_Web_SDK_v5.8.0')
// import './NIM_Web_SDK_v5.8.0'

// 初始化 NIM SDK
export function initUnreadNimSDK({state, commit, dispatch}, loginInfo) {
    if (process.server) {
        return
    }
    if (state.unreadNim) {
        state.unreadNim.disconnect()
    }
    let sessionList = []
    // 初始化SDK
    window.unreadNim = SDK.NIM.getInstance({
        appKey: configs.appkey,
        account: loginInfo.yunxinAccId,
        token: loginInfo.token,
        transports: ['websocket'],
        db: false,
        syncSessionUnread: true,
        syncRobots: true,
        needReconnect: true,
        shouldIgnoreNotification(msg) {
            return msg.type === 'notification'
        },
        autoMarkRead: true, // 默认为true,false时SDK就不会控制信息自动为已读
        onerror: function onError(event) {
            console.log('Internal error')
        },
        // 会话
        onsessions(list) {
            sessionList = list
            console.log(list)
            commit('SET_CHAT_UNREAD', sessionList.reduce((count, item) => (item.unread ? item.unread + count : count), 0), { root: true })
        },
        onupdatesession(session) {
            sessionList = window.unreadNim.mergeSessions(sessionList, session)
            commit('SET_CHAT_UNREAD', sessionList.reduce((count, item) => (item.unread ? item.unread + count : count), 0), { root: true })
        },
    })
    // commit('SET_NIM', window.unreadNim)
}
