const config = {
    sdk: 'NIM_Web_SDK_v5.8.0',
    // 用户自定义的登录注册地址
    loginUrl: '/login',
    registUrl: '/webdemo/h5/regist.html',
    homeUrl: '/home',

    // 资源路径根目录，为了方便用户部署在二级以上URL路径上
    resourceUrl: 'http://yx-web.nos.netease.com/webdoc/h5',
    // 用户logo地址
    logo: 'http://yx-web.nos.netease.com/webdoc/h5/im/logo.png',
    // 默认用户头像
    defaultUserIcon: 'http://yx-web.nos.netease.com/webdoc/h5/im/default-icon.png',
    // 默认普通群头像
    defaultGroupIcon: 'http://yx-web.nos.netease.com/webdoc/h5/im/default-group.png',
    // 默认高级群头像
    defaultAdvancedIcon: 'http://yx-web.nos.netease.com/webdoc/h5/im/default-advanced.png',
    // 系统通知图标
    noticeIcon: 'http://yx-web.nos.netease.com/webdoc/h5/im/notice-icon.png',
    // 我的手机图标
    myPhoneIcon: 'http://yx-web.nos.netease.com/webdoc/h5/im/my-phone.png',
    // 本地消息显示数量，会影响性能
    localMsglimit: 30,
    apiUrlPrefix: '/imserver',
}

const env = process.env.CUSTOM_ENV
console.log('env', env)
let appConfig = {
    // 用户的appkey
    // 用于在web demo中注册账号异步请求demo 服务器中使用
    development: {
        appkey: '7c1230b689e1e55fa1636f16cf6e0631',
        postUrl: 'https://app.netease.im',
    },
    // 连 stable 环境用
    // development: {
    //     appkey: '874f37726ec4da6598a2d9b06166192a',
    //     postUrl: 'https://app.netease.im',
    // },
    // test 环境
    test: {
        appkey: '7c1230b689e1e55fa1636f16cf6e0631',
        postUrl: 'https://app.netease.im',
    },
    // test 环境
    testbig: {
        appkey: '7c1230b689e1e55fa1636f16cf6e0631',
        postUrl: 'https://app.netease.im',
    },
    // uat 环境
    uat: {
        appkey: '7c1230b689e1e55fa1636f16cf6e0631',
        postUrl: 'https://app.netease.im',
    },
    // stable环境
    stable: {
        appkey: '874f37726ec4da6598a2d9b06166192a',
        postUrl: 'https://app.netease.im',
    },
    // 线上环境
    production: {
        appkey: '8145f2f60b472749451971749228da6e',
        postUrl: 'https://app.netease.im',

    },
}

const mergeConfig = Object.assign(config, appConfig[env])

export default mergeConfig
